<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>
    <div>
      <v-toolbar-title
        class="hidden-sm-and-down font-weight-light"
        v-text="$t($route.name)"
      />

      <div v-if="this.subtitle">
        <v-flex>
          <div class="text d-flex justify-space-between">
            <span v-text="this.subtitle_id"> </span>
            <span
              class="text--disabled mx-1"
              v-text="this.subtitle"
            >
            </span>
          </div>
        </v-flex>
      </div>
    </div>
    <div class="locale-changer mx-15">
      <v-select
        style="width: 70px"
        hide-details
        v-model="currentLang"
        :items="langs"
      ></v-select>
    </div>
    <template v-if="$route.name == 'ops-new.create'">
      <v-icon
        v-if="isOnline"
        class="ml-3"
        color="success"
        size="30"
      >
        mdi-network-pos
      </v-icon>
      <v-icon
        v-else
        class="ml-3"
        color="error"
        size="30"
      >
        mdi-network-off
      </v-icon>
    </template>
    <v-spacer />

    <!-- <v-text-field :label="$t('search')" color="secondary" hide-details style="max-width: 165px;">
        <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:append-outer>
            <v-btn class="mt-n2" elevation="1" fab small>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </template>
    </v-text-field> -->

    <div class="mx-3" />
    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/dashboard"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-store</v-icon>
          <span class="px-2"> {{ user.store_name }}</span>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div
          v-for="store in stores"
          :key="store.id"
          @click="updateStore(store)"
        >
          <app-bar-item>
            <v-list-item-title v-text="store.name" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>

    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/#profile"
    >
      <v-icon>mdi-account</v-icon>
      <span class="px-2">{{ this.user.email }}</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

import { network } from "@/mixins/network";

export default {
  name: "DashboardCoreAppBar",
  mixins: [network],

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [],
    currentLang: "fr",
    langs: [
      { text: "FR", value: "fr" },
      { text: "EN", value: "en" },
    ],
  }),

  mounted() {
    this.currentLang = localStorage.currentLang
      ? localStorage.currentLang
      : this.$i18n.locale;
  },

  watch: {
    currentLang(newLang) {
      localStorage.currentLang = newLang;
      this.$i18n.locale = newLang;
    },
  },

  computed: {
    ...mapState(["drawer"]),
    user() {
      return this.$store.getters.getUser;
    },
    stores() {
      return this.user.stores;
    },

    subtitle() {
      return this.$store.state.page_subtitle;
    },
    subtitle_id() {
      return this.$store.state.subtitle_id;
    },
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    async updateStore(store) {
      try {
        await this.$store.dispatch("updateMyStore", {
          next_store_id: store.id,
        });
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
